import * as React from "react";

import * as _ from "lodash";
import * as moment from "moment";

import {
  Sidebar,
  Dropdown,
  Loader,
  DropdownItemProps,
  Button,
  Icon,
  LabelProps
} from "semantic-ui-react";

import RequestView from "./RequestView";
import RequestSidebar from "./Sidebar";

import { ItemsPerPage, Pagination, Header } from "Component";

import {
  RequestItem,
  RequestFilters,
  RequestItemList,
  Sorting
} from "./interfaces.d";

import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";

import {
  UserService,
  TranslationService,
  languages,
  RequestService,
  ShipmentService
} from "Services";

import { reaction } from "mobx";

import styles from "./index.less";
import GeneralLoader from "Utilities/GeneralLoader";

const isEmployee = UserService.isEmployee();

const InitialState = {
  isEmployee,
  selectedCompany: UserService.getSelectedCompany(),
  requests: { Requests: [], TotalRequests: 0 },
  sidebarVisible: false,
  filters: {
    Partner: "",
    RequestNumber: "",
    ContainerNumber: "",
    DateFrom: new Date(),
    DateTo: new Date(),
    RequestVersion: "",
    RequestType: "",
    RequestStatuses: [],
    Department: ""
  },
  requestsPerPage: 10,
  currentPage: 1,
  isLoading: true,
  sortOptions: [
    {
      element: "LABEL_REQUESTNUMBERS",
      value: "RequestID",
      text: ""
    },
    {
      element: "LABEL_SHIPPER_FROM",
      value: "From",
      text: ""
    },
    {
      element: "LABEL_RECIPIENT_FROM",
      value: "To",
      text: ""
    },
    {
      element: "LABEL_CREATOR_FROM",
      value: "User",
      text: ""
    },
    {
      element: "LABEL_CREATE_DATE_FROM",
      value: "DateCreated",
      text: ""
    }
  ],
  sorting: {
    SortBy: "RequestID",
    IsDesc: false
  },
  labels: [],
  requestTypeOptions: [],
  departmentOptions: []
};

export default class Page extends React.Component<
  {},
  {
    requests: RequestItemList;
    sidebarVisible: boolean;
    selectedCompany: Company;
    filters: RequestFilters;
    requestsPerPage: number;
    currentPage: number;
    isLoading: boolean;
    sortOptions: DropdownItemProps[];
    sorting: Sorting;
    labels: LabelProps[];
    isEmployee: boolean;
    requestTypeOptions: DropdownItemProps[];
    departmentOptions: DropdownItemProps[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = _.cloneDeep(InitialState);
  }

  async componentDidMount() {
    const selectedCompany = UserService.getSelectedCompany();
    reaction(
      () => TranslationService.getSelectedLanguage(),
      async () => {
        await this.createDropdownOptions();
      }
    );

    await this.createDropdownOptions();

    const WebSettingsFiltersJSON = UserService.getLocalWebSettingValue(
      USERWEBSETTINGS.RequestListPage
    );

    if (WebSettingsFiltersJSON) {
      const WebSettingsFilters: {
        DateFrom: any;
        DateTo: any;
        RequestsPerPage: any;
      } = JSON.parse(WebSettingsFiltersJSON);
      const filters = this.state.filters;
      filters.DateFrom = WebSettingsFilters.DateFrom
        ? new Date(WebSettingsFilters.DateFrom)
        : new Date();
      filters.DateTo = WebSettingsFilters.DateTo
        ? new Date(WebSettingsFilters.DateTo)
        : new Date();

      const mDateFrom = moment(filters.DateFrom);
      const mDateTo = moment(filters.DateTo);

      if (mDateTo.diff(mDateFrom, "months", true) > 3) {
        filters.DateFrom = new Date(
          mDateTo.subtract(3, "months").format("YYYY-MM-DD")
        );
      }

      const requestsPerPage =
        WebSettingsFilters.RequestsPerPage || this.state.requestsPerPage;
      this.setState({ filters, requestsPerPage, selectedCompany }, () =>
        this.onFilter(
          "partner",
          "LABEL_CUSTOMERGROUP",
          selectedCompany.PartnerCode,
          true
        )
      );
    } else {
      this.setState({ selectedCompany }, () =>
        this.onFilter(
          "partner",
          "LABEL_CUSTOMERGROUP",
          selectedCompany.PartnerCode,
          true
        )
      );
    }
  }

  createDropdownOptions = async () => {
    const tmpSortOptions = _.cloneDeep(InitialState.sortOptions);
    const sortOptions = tmpSortOptions.map(item => this.translateText(item));

    const requestTypes = await RequestService.getRequestTypes();
    const requestTypeOptions: DropdownItemProps[] = [];
    requestTypes.forEach((type, i) => {
      requestTypeOptions.push({
        key: i,
        value: type,
        text: languages(type)
      });
    });

    const departments = await ShipmentService.getDepartments();
    const departmentOptions: DropdownItemProps[] = [];

    departments.forEach((department, i) => {
      departmentOptions.push({
        key: i,
        value: department.Department,
        text: languages(department.DepartmentName)
      });
    });
    this.setState({ sortOptions, requestTypeOptions, departmentOptions });
  };

  setRequestsPerPage = (value: number) => {
    this.setState({ requestsPerPage: value, currentPage: 1 }, () => {
      this.getRequests();
      this.saveWebSettings();
    });
  };

  onPageChange = (value: number) => {
    this.setState({ currentPage: value, isLoading: true }, this.getRequests);
  };

  onExcel = async () => {
    const req = await this.getRequestsForExcel();

    const res = await RequestService.getExcelForRequests(req.Requests);
    return res;
  };

  getRequestsForExcel = async (): Promise<any> => {
    const { filters } = this.state;
    if (filters.RequestNumber) {
      return await RequestService.getRequestList(
        !UserService.isEmployee ? filters.Partner : undefined,
        undefined,
        undefined,
        0,
        0,
        filters.RequestNumber,
        undefined,
        undefined,
        undefined,
        [],
        undefined,
        undefined,
        false
      );
    }
    return await RequestService.getRequestList(
      filters.Partner,
      filters.DateFrom.toDateString(),
      filters.DateTo.toDateString(),
      0,
      0,
      filters.RequestNumber,
      filters.ContainerNumber,
      filters.RequestVersion,
      filters.RequestType,
      filters.RequestStatuses,
      filters.Department,
      undefined,
      false
    );
  };

  getRequests = async (): Promise<any> => {
    const { filters, requestsPerPage, currentPage, sorting } = this.state;
    this.setState({ isLoading: true });
    GeneralLoader.increase();
    if (filters.RequestNumber) {
      await RequestService.getRequestList(
        !UserService.isEmployee ? filters.Partner : undefined,
        undefined,
        undefined,
        requestsPerPage,
        requestsPerPage * (currentPage - 1),
        filters.RequestNumber,
        undefined,
        undefined,
        undefined,
        [],
        undefined,
        sorting.SortBy,
        sorting.IsDesc
      )
        .then((requests: RequestItemList) => {
          if (requests.Requests == null) {
            requests = {
              Requests: [],
              TotalRequests: 0
            };
          }
          if (requests != null) {
            this.setState({ requests });
          }
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        })
        .then(() => {
          GeneralLoader.decrease();
        })
        .catch(() => {
          GeneralLoader.decrease();
        });
    } else {
      await RequestService.getRequestList(
        filters.Partner,
        filters.DateFrom.toDateString(),
        filters.DateTo.toDateString(),
        requestsPerPage,
        requestsPerPage * (currentPage - 1),
        filters.RequestNumber,
        filters.ContainerNumber,
        filters.RequestVersion,
        filters.RequestType,
        filters.RequestStatuses,
        filters.Department,
        sorting.SortBy,
        sorting.IsDesc
      )
        .then((requests: RequestItemList) => {
          if (requests.Requests == null) {
            requests = {
              Requests: [],
              TotalRequests: 0
            };
          }
          if (requests != null) {
            this.setState({ requests });
          }
          this.setState({ isLoading: false });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        })
        .then(() => {
          GeneralLoader.decrease();
        })
        .catch(() => {
          GeneralLoader.decrease();
        });
    }
  };

  translateText = (item: any): any => {
    return { ...item, text: languages(item.element) };
  };

  onFilter = async (
    key: string,
    label: string,
    value: any,
    update: boolean
  ) => {
    this.state.filters[key] = value;
    if (
      !this.labelExists(label) &&
      ((!Array.isArray(value) && value) || value.length) &&
      label
    ) {
      this.addLabel(key, label);
    } else if (
      this.labelExists(label) &&
      (!value || (Array.isArray(value) && !value.length))
    ) {
      this.removeLabel(key);
    }

    const WebSettingsFilterKeys = [
      "DateFrom",
      "DateTo"
    ];
    if (update) {
      this.setState(
        {
          filters: this.state.filters,
          isLoading: true,
          currentPage: 1
        },
        () => {
          this.getRequests();
          if (WebSettingsFilterKeys.indexOf(key) > -1) {
            this.saveWebSettings();
          }
        }
      );
    } else {
      this.setState(
        {
          filters: this.state.filters
        },
        () => {
          if (WebSettingsFilterKeys.indexOf(key) > -1) {
            this.saveWebSettings();
          }
        }
      );
    }
  };

  labelExists = (label: string): boolean => {
    return this.state.labels.some(item => item.content === label);
  };

  addLabel = (key: string, label: string): void => {
    this.state.labels.push({ key, content: label });
    this.setState({ labels: this.state.labels });
  };

  removeLabel = (key: string): void => {
    const newArr = this.state.labels.filter(item => {
      return item.key !== key;
    });
    this.setState({ labels: newArr });
  };

  removeFilter = (key: string): void => {
    this.removeLabel(key);
    this.state.filters[key] = InitialState.filters[key];
    this.setState({ filters: this.state.filters }, this.getRequests);
  };

  clearFilters = (): void => {
    const keys = Object.keys(this.state.filters);
    const filterArr: any[] = [];
    keys.forEach(key => {
      if (key === "Partner") {
        this.onFilter(
          "Partner",
          "LABEL_CUSTOMERGROUP",
          this.state.selectedCompany.PartnerCode,
          false
        );
      } else if (
        key !== "DateFrom" &&
        key !== "DateTo"
      ) {
        filterArr.push(key);
        this.state.filters[key] = InitialState.filters[key];
      }
    });
    this.setState(
      {
        filters: this.state.filters,
        labels: this.state.labels.filter(item => {
          return (
            filterArr.indexOf(item.key) < 0 ||
            item.key === "Partner"
          );
        })
      },
      this.getRequests
    );
  };

  onSorting = (key: string, value: any) => {
    this.state.sorting[key] = value;

    this.setState(
      {
        sorting: this.state.sorting,
        isLoading: true
      },
      this.getRequests
    );
  };

  saveWebSettings = () => {
    UserService.setWebSetting(
      USERWEBSETTINGS.RequestListPage,
      JSON.stringify({
        DateFrom: this.state.filters.DateFrom,
        DateTo: this.state.filters.DateTo,
        RequestsPerPage: this.state.requestsPerPage
      })
    );
  };

  closeSidebar = () => this.setState({ sidebarVisible: false });
  openSidebar = () => this.setState({ sidebarVisible: true });

  render() {
    const {
      requests,
      sidebarVisible,
      requestsPerPage,
      currentPage,
      isLoading,
      sortOptions,
      sorting,
      filters,
      selectedCompany,
      labels,
      isEmployee
    } = this.state;

    return (
      <Sidebar.Pushable className={`row ListPage clearfix ${styles.pushable}`}>
        <RequestSidebar
          disabled={isLoading}
          visible={sidebarVisible}
          onClose={this.closeSidebar}
          filters={filters}
          onFilter={this.onFilter}
          selectedCompany={selectedCompany}
          isEmployee={this.state.isEmployee}
          onSearch={this.getRequests}
          requestTypes={this.state.requestTypeOptions}
          departments={this.state.departmentOptions}
          clearFilters={this.clearFilters}
        />
        <Sidebar.Pusher
          className={`col-xs-12 js-ListPage-box ${
            sidebarVisible ? "js-ListPage-box-enlarge" : ""
          }`}
        >
          <div className={`ListPage-content ${styles.content}`}>
            <Header
              site="request"
              count={requests.TotalRequests}
              openSideBar={this.openSidebar}
              labels={labels}
              removeFilter={this.removeFilter}
              onExcel={this.onExcel}
            />
            <div className="js-DisplayList u-posRelative">
              <ItemsPerPage
                items={[10, 25, 50, 100]}
                value={requestsPerPage}
                onValue={this.setRequestsPerPage}
                disabled={isLoading}
              ></ItemsPerPage>
              <div className="row">
                <div className="col-sm-6 col-md-3">
                  <span>
                    {`${languages("LABEL_SORTBY")} `}
                    <Dropdown
                      inline
                      options={sortOptions}
                      disabled={isLoading}
                      defaultValue={this.state.sorting.SortBy}
                      onChange={(e, d) => this.onSorting("sortBy", d.value)}
                    />
                    <Button
                      icon
                      compact
                      size="massive"
                      basic
                      disabled={isLoading}
                      onClick={() => this.onSorting("isDesc", !sorting.IsDesc)}
                    >
                      {sorting.IsDesc ? (
                        <Icon name="sort alphabet down" />
                      ) : (
                        <Icon name="sort alphabet up" />
                      )}
                    </Button>
                  </span>
                </div>
                <div className="col-sm-6 col-md-3"></div>
              </div>
              <div className="js-Tile-wrapper">
                {requests
                  ? requests.Requests.map((request: RequestItem, i: number) => {
                      return (
                        <RequestView
                          isEmployee={isEmployee}
                          request={request}
                          key={i}
                        />
                      );
                    })
                  : null}
                {requests &&
                  requests.TotalRequests == 0 &&
                  !this.state.isLoading && (
                    <div className="ListPage-noResults">
                      {languages("TEXT_NORESULTS")}
                    </div>
                  )}
                <Loader active={isLoading} size="big" />
              </div>
              <div className="DisplayList-paginator col-sm-12 col-lg-12">
                {requests.TotalRequests > 0 && (
                  <Pagination
                    page={currentPage}
                    totalPages={Math.ceil(
                      requests.TotalRequests / this.state.requestsPerPage
                    )}
                    onPageChange={this.onPageChange}
                    disabled={isLoading}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}
