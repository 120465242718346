import * as angular from "angular";
import * as moment from "moment";
import { autorun } from "mobx";
import { $filter, $timeout } from "ngimport";
import { UIRouter, StateService, Transition } from "@uirouter/core";
import { Object } from "es6-shim";
import { MAIN_STATE } from "./index";
import DomesticWaybillModal from "Domestic/waybillModal/DomesticWaybillModal";
import { $uiRouter } from "Bootstrap/angular";
import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import {
  SamskipNotify,
  UserService,
  TranslationService,
  DomesticService,
} from "Services";
import RouteStore from "../shared/stores/RouteStore";
import { UtilityFunctions, ArrayUtilities, DateUtilities } from "Utilities";
import * as _ from "lodash";
import GeneralLoader from "Utilities/GeneralLoader";
import DomesticDropdownService from "./DomesticDropdownService";

declare var $: any;

function domesticController($scope: any, $transition$: Transition) {
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();
  const previousState = !RouteStore.previousState
    ? MAIN_STATE
    : RouteStore.previousState;

  const currentUser = UserService.getUserProfile();
  const selectedCompany = UserService.getSelectedCompany();

  loadDateSettings();

  $scope.resultText = currentStateParams.resultText;

  $scope.Filter = initFilter();

  $scope.FilterBase = initFilter();

  $scope.itemsSelected = 0;

  autorun(() => {
    const listViewSize = UserService.getWebSettingObservable(
      USERWEBSETTINGS.SelectedListViewSize
    );
    if (!listViewSize) return;
    $scope.listViewSize = UtilityFunctions.getListViewSizeClass(
      listViewSize.StringValue
    );
  });

  dataLoader(currentState, currentStateParams);

  $scope.DomesticSettings = {
    DateTo: $scope.dateFrom,
    DateFrom: $scope.dateTo,
  };

  $scope.SaveDateRange = function SaveDateRange(
    DateFrom: string,
    DateTo: string
  ) {
    $scope.DomesticSettings.DateFrom = DateFrom;
    $scope.DomesticSettings.DateTo = DateTo;

    UserService.setWebSetting(
      USERWEBSETTINGS.DomesticListPage,
      JSON.stringify($scope.DomesticSettings)
    );
  };

  this.formatDate = DateUtilities.formatDate;

  const listPageSetting = UserService.getLocalWebSettingValue(
    USERWEBSETTINGS.DomesticListPage
  );
  // checks WebSettings for date settings
  if (listPageSetting) {
    $scope.DomesticSettings = JSON.parse(listPageSetting);
    const mDateFrom = moment($scope.DomesticSettings.DateFrom);
    const mDateTo = moment($scope.DomesticSettings.DateTo);

    if (mDateTo.diff(mDateFrom, "months", true) > 3) {
      $scope.dateFrom = mDateTo.subtract(3, "months").format("YYYY-MM-DD");
    } else {
      $scope.dateFrom = $scope.DomesticSettings.DateFrom;
    }
    $scope.dateTo = $scope.DomesticSettings.DateTo;
  }

  $scope.translate = function translate(str: string) {
    return TranslationService.translate(str);
  };

  $scope.errors = {
    search: false,
  };

  // pagination
  $scope.totalBookings = 0;
  $scope.currentPage = 1;
  $scope.maxSize = 3;

  /*
   * Items Per Page:
   * Everything regarding how many items to display per page
   */

  // Default value
  $scope.itemsPerPage = 10;

  // Get the settings for how many items per list page to display
  const settingsItemsPerListPage = UserService.getLocalWebSettingValue(
    USERWEBSETTINGS.ItemsPerListPage
  );
  if (settingsItemsPerListPage) {
    $scope.itemsPerPage = settingsItemsPerListPage;
  }

  // When items per page has changed, this event is triggered
  // which lets us save settings and fade list in and out
  $scope.$on("itemsPerPageChanged", (event: any, value: any) => {
    // Save the selection on the server
    UserService.setWebSetting(USERWEBSETTINGS.ItemsPerListPage, value);

    // Display loading animation for list
    (angular.element(".js-Tile-wrapper") as any).fadeTo(100, 0);
    (angular.element(".loaderDomesticList") as any)
      .fadeTo(300, 1)
      .delay(600)
      .fadeTo(300, 0, () => {
        (angular.element(".js-Tile-wrapper") as any).fadeTo(100, 1);
      });
  });

  $scope.modal = { method: "none" };

  UtilityFunctions.checkSizes($(".js-Sidebar"), $(".js-ListPage-box"));

  $scope.search = function search(query: number): void {
    if (query) {
      if (query < 10000 && query > 0) {
        $scope.errors.search = true;
        return;
      }
      searchTransports(query.toString());
    } else {
      getDomesticTransports();
    }
  };

  async function searchTransports(query?: string) {
    if (!currentUser) return;
    const selectedCompany = UserService.getSelectedCompany();
    if ($scope.inListAPICall) return;
    if (query === undefined || query.length < 1) {
      $state.go(MAIN_STATE);
      return;
    }

    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderDomesticList").fadeTo(400, 1);
    $scope.domestics = [];

    $scope.inListAPICall = true;
    GeneralLoader.increase();
    DomesticService.searchCompanyTransports(query, selectedCompany.CompanyRegNo)
      .then((data) => {
        handleData(data);
      })
      .catch(() => {
        // Handle the error here if needed.
        $scope.inListAPICall = false;
      })
      .finally(() => {
        $(".loaderDomesticList").hide(200);
        GeneralLoader.decrease();
        $scope.inListAPICall = false;
      });
  }

  $scope.filters = function filters(item: any) {
    let addResult = true;

    if ($scope.Filter.statusSelection.value.length > 0) {
      const tileStatus = DomesticService.trackTraceStatusToTileStatus(
        item.TrackTraceStatus
      );
      addResult =
        $scope.Filter.statusSelection.value.indexOf(tileStatus) !== -1;
    }

    if (!isEmptyArray($scope.Filter.senderHub.value)) {
      if (
        !itemExistInArray($scope.Filter.senderHub.value, item.ShipFromHubName)
      ) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.Filter.recipientHub.value)) {
      if (
        !itemExistInArray($scope.Filter.recipientHub.value, item.ShipToHubName)
      ) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.Filter.senderName.value)) {
      if (
        !itemExistInArray($scope.Filter.senderName.value, item.ShipFromName)
      ) {
        addResult = false;
      }
    }

    if (!isEmptyArray($scope.Filter.recipientName.value)) {
      if (
        !itemExistInArray($scope.Filter.recipientName.value, item.ShipToName)
      ) {
        addResult = false;
      }
    }

    if (
      filterSenderOrRecipient(
        $scope.Filter.senderOrRecipient.value,
        selectedCompany.CompanyRegNo,
        item
      )
    ) {
      addResult = false;
    }

    if (filterExternalSignature($scope.Filter.externalSignature.value, item)) {
      addResult = false;
    }

    if (angular.equals($scope.Filter, $scope.FilterBase)) {
      $(".js-ContentHeader-clearFilters").hide();
    } else {
      $(".js-ContentHeader-clearFilters").show();
    }

    if (item.checked && !addResult) {
      delete item.checked;
      $scope.itemsSelected -= 1;

      if ($scope.itemsSelected === 1) {
        $scope.hideMultiActionBar();
      }
    }

    return addResult;
  };

  /**
   * Check if sender should be filtered from an item
   * @param senderOrRecipient Sender or Recipient filter option
   * @param companyRegNo Company SSN
   * @param item Item to filter
   */
  function filterSenderOrRecipient(
    senderOrRecipient: string,
    companyRegNo: string,
    item: any
  ): boolean {
    if (senderOrRecipient !== "all" && currentUser) {
      if (senderOrRecipient === "sender") {
        if (companyRegNo !== item.ShipFromKt) return true;
      } else {
        if (companyRegNo !== item.ShipToKt) return true;
      }
    }
    return false;
  }

  /**
   * Check if item should be filtered by external signature
   * @param externalSignature Filter option
   * @param item Item to filter
   */
  function filterExternalSignature(
    externalSignature: string,
    item: any
  ): boolean {
    if (externalSignature !== "all") {
      if (externalSignature === "yes") {
        if (item.ExternalSignatures < 1) return true;
      } else {
        if (item.ExternalSignatures >= 1) return true;
      }
    }
    return false;
  }

  $scope.domestics = [];

  // init for daterange state
  if (
    currentStateParams.dateFrom !== undefined &&
    currentStateParams.dateTo !== undefined
  ) {
    $scope.dateFrom = currentStateParams.dateFrom;
    $scope.dateTo = currentStateParams.dateTo;
  }

  if (!$scope.dateFrom) {
    $scope.dateFrom = moment().subtract(2, "weeks").format("YYYY-MM-DD");
  }

  if (!$scope.dateTo) {
    $scope.dateTo = moment().format("YYYY-MM-DD");
  }

  UtilityFunctions.largeSpinner("largeSpinner");

  // posts a request to server and returns a promise which is used in the watch lower on the page
  async function getDomesticTransports() {
    if ($scope.inListAPICall) return;

    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderDomesticList").fadeTo(400, 1);

    if (currentUser && selectedCompany.CompanyRegNo) {
      $scope.inListAPICall = true;
      GeneralLoader.increase();
      DomesticService.companyTransportsList(
        $scope.dateFrom,
        $scope.dateTo,
        selectedCompany.CompanyRegNo
      )
        .then(handleData)
        .then(() => {
          $scope.inListAPICall = false;
        })
        .catch(() => {
          $scope.inListAPICall = false;
          GeneralLoader.decrease();
        });
    } else {
      $(".loaderDomesticList")
        .clearQueue()
        .fadeTo(400, 0, () => {
          $(".js-DisplayList").fadeTo(500, 1, () => {
            $(".js-ListPage-box").trigger("heightChange");
          });
        });

      SamskipNotify.displayError("TEXT_NOCOMPANYREGNO");
    }
  }

  $scope.multiActionSelected = [];
  $scope.numberOfSelected = $scope.multiActionSelected.length;

  /**
   * Toggle status in filters.
   * If the statuses exist in filters selected value,
   * we remove them, otherwise we add them.
   * @param  {Number/Array} status Single status or array of statuses
   */
  $scope.toggleStatusSelection = function toggleStatusSelection(
    status: any[]
  ): any {
    let statusArr = status;
    $timeout(() => {
      // Always make the status an array, because we can toggle
      // both a single status and an array of statuses
      if (!Array.isArray(status)) {
        statusArr = [status];
      }

      // Clone the status filter array so we don't modify the scope
      // variable and immediately run digest cycle
      const statusFilterArray: any = ArrayUtilities.copy(
        $scope.Filter.statusSelection.value
      );

      // Go through all the statuses in the array
      // and toggle them in the status filter
      statusArr.forEach((item) => {
        const idx = statusFilterArray.indexOf(item);

        if (idx > -1) {
          // Status exists in the filter array, then we remove it
          statusFilterArray.splice(idx, 1);
        } else {
          // Status doesn't exist in the filter array, then we add it
          statusFilterArray.push(item);
        }
      });

      // Change filter array in scope and run digest cycle
      $scope.Filter.statusSelection.value = statusFilterArray;
    });
  };

  $scope.setSenderOrRecipient = function setsenderOrRecipient(value: string) {
    $timeout(() => {
      $scope.Filter.senderOrRecipient.value = value;
    });
  };

  $scope.setExternalSignature = function setExternalSignature(value: string) {
    $timeout(() => {
      $scope.Filter.externalSignature.value = value;
    });
  };

  $scope.showWayBillModal = function showWayBillModal(
    bookingNo: string,
    shipmentID: string
  ) {
    DomesticWaybillModal.openModal(bookingNo, shipmentID);
  };

  $scope.samExcel = {};

  $scope.samExcel.excelButtonTitle = function excelButtonTitle() {
    return "Excel";
  };

  // Downloads domestic transport list in Excel
  // API responds with a Excel file
  $scope.samExcel.downloadExcel = function downloadExcel() {
    if (!currentUser) return;
    const transports: string[] = $scope.filteredDomestic.map(
      (item: DomesticTransport) => item.ShpmtId
    );
    const ssn = selectedCompany.CompanyRegNo;

    return DomesticService.transportsInExcel(
      ssn,
      $scope.dateFrom,
      $scope.dateTo,
      transports
    );
  };

  $scope.samExcel.excelFileName = function excelFileName() {
    return `TransportList.${$scope.dateFrom}-${$scope.dateTo}`;
  };

  UtilityFunctions.catToggle();
  UtilityFunctions.filterSlideControl(
    "js-Sidebar-triggerFilter",
    "js-Sidebar",
    "LABEL_OPEN_FILTER",
    "LABEL_CLOSE_FILTER"
  );

  // ************ Ordering and Filter functions ******************/

  $scope.selectedPredicate = "CreateDateTime";
  $scope.selectedPredicateTranslation = "LABEL_IT_CREATED";
  const orderBy = $filter("orderBy");
  $scope.reverse = false;

  // For sorting bookings
  $scope.order = function order() {
    $scope.domestics = orderBy(
      $scope.domestics,
      $scope.selectedPredicate,
      $scope.reverse
    );
  };

  $scope.setPredicate = function setPredicate(
    pred: string,
    translation: string
  ) {
    $scope.selectedPredicate = pred;
    $scope.selectedPredicateTranslation = translation;
  };

  $scope.removeFilter = function removeFilter(filter: any) {
    angular.forEach($scope.Filter, (value, key) => {
      if (filter.name === value.name) {
        angular.forEach(initFilter(), (initValue) => {
          if (initValue.name === value.name) {
            $scope.Filter[key] = initValue;
          }
        });
      }
    });
  };

  $scope.resetAllFilters = function resetAllFilters() {
    $scope.Filter = initFilter();
  };

  /* INTRO START */

  $scope.tutorialSteps = [
    {
      element: "#FilterSidebar",
      title: "Filter",
      content: "TUTOR_SEARCH",
      position: "left",
    },
  ];

  /* INTRO END */

  /**
   * Datepicker functionality
   */
  $scope.datePicker = {
    datesUpdated: function datesUpdated(
      startDate: moment.Moment,
      endDate: moment.Moment
    ) {
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");

      $scope.dateFrom = formattedStartDate;
      $scope.dateTo = formattedEndDate;
      $scope.SaveDateRange(formattedStartDate, formattedEndDate);

      getDomesticTransports();
    },
  };

  $scope.itemChecked = function itemChecked(checked: boolean) {
    if (checked) {
      $scope.itemsSelected += 1;
    } else {
      $scope.itemsSelected -= 1;
    }

    if ($scope.itemsSelected > 1) {
      $scope.showMultiActionBar();
    } else {
      $scope.hideMultiActionBar();
    }
  };

  $scope.hideMultiActionBar = function hideMultiActionBar() {
    if ($scope.itemsSelected <= 1 && $scope.multiActionBarVisible) {
      // Disable the $scope flag to indicate that the selectedBar is hidden
      $scope.multiActionBarVisible = false;

      $(".selectedBar").fadeTo(600, 0, () => {
        $(".selectedBar").css("display", "none");
      });
    }
  };

  $scope.showMultiActionBar = function showMultiActionBar() {
    // Show the selectedBar if not already visible
    if ($scope.itemsSelected > 1 && !$scope.multiActionBarVisible) {
      $scope.multiActionBarVisible = true;

      $(".selectedBar")
        .css({
          opacity: 0,
          display: "block",
        })
        .fadeTo(600, 1);
    }
  };

  /**
   * Called when the user toggles selection of all/none
   * @param  {Boolean} isChecked  Current state of multiselection
   */
  $scope.multiselectToggled = function multiselectToggled(isChecked: boolean) {
    if (isChecked) {
      selectAllDomestics();
    } else {
      selectNone();
    }
  };

  /**
   * Handle proof of delivery for each selected bookings.
   */
  $scope.EPOD = {
    download: downloadEPOD,
    downloadButtonTitle: function dbt() {
      return TranslationService.translate("LABEL_PROOFOFDELIVERY");
    },
  };

  function downloadEPOD() {
    const checkedDomestics = getCheckedDomestics();
    const shipmentIds = checkedDomestics.map(
      (item: DomesticTransport) => item.ShpmtId
    );

    return DomesticService.downloadSignaturesPDF(shipmentIds);
  }

  /**
   * Get all shipments that are currently selected.
   */
  function getCheckedDomestics(): any[] {
    const jobRefs: any[] = [];
    angular.forEach($scope.domestics, (item: any) => {
      if (item.checked) {
        jobRefs.push(item);
      }
    });
    return jobRefs;
  }

  /**
   * Select all domestics that are currently displayed.
   */
  function selectAllDomestics(): void {
    let checked = 0;
    $scope.filteredDomestic.forEach((it: DomesticTransport | any) => {
      it.checked = true;
      checked += 1;
    });
    $scope.itemsSelected = checked;
    $scope.showMultiActionBar();
  }

  /**
   * Deselect all domestics that are currently displayed.
   */
  function selectNone(): void {
    $scope.filteredDomestic.forEach((it: DomesticTransport | any) => {
      it.checked = false;
    });
    $scope.itemsSelected = 0;
    $scope.hideMultiActionBar();
  }

  $scope.openSignature = function openSignature(shipmentId: string) {
    DomesticService.openSignature(shipmentId);
  };

  function setDomesticsForAgent(
    data: DomesticTransport[],
    userId: number,
    subSys: number
  ) {
    DomesticService.getUserHubIds(userId, subSys).then((hubIds: string[]) => {
      $scope.domestics = _.filter(data, (item) => {
        return _.some([item.ShipFromHub, item.ShipToHub], (value) =>
          _.includes(hubIds, value)
        );
      });
    });
  }

  function handleData(data: DomesticTransport[]) {
    GeneralLoader.decrease();
    // some animation
    $(".loaderDomesticList")
      .clearQueue()
      .fadeTo(400, 0, () => {
        $(".js-DisplayList").fadeTo(500, 1, () => {
          $(".js-ListPage-box").trigger("heightChange");
        });
      });
    let shipmentIds: string[] = [];
    for (let i = 0; i < data.length; i++) {
      shipmentIds.push(data[i].ShpmtId);
    }

    /* DomesticService.getShipmentCollectionForwarding(shipmentIds).then(
      (data) => {
        const hasBarcodeMap = {};

        for (const item of data) {
          hasBarcodeMap[item.CollectionId] = item.ForwardingId;
        }
        ///console.log(hasBarcodeMap);

        $scope.domestics.forEach((shipment: DomesticTransport) => {
          if (hasBarcodeMap[parseInt(shipment.ShpmtId)] > 0) {
            shipment.HasBarcode = true;
          } else {
            shipment.HasBarcode = false;
          }
        });
      }
    );*/

    // If user is an Agent, filter domestics list with hubs that are assigned to the agent
    if (currentUser && currentUser.Access.UserType === "A") {
      setDomesticsForAgent(
        data,
        currentUser.Access.UserID,
        currentUser.Access.SubSys
      );
    } else {
      $scope.domestics = data;
    }

    if (currentState === "domestic_active") {
      $scope.dateFrom = currentStateParams.dateFrom;
      $scope.dateTo = currentStateParams.dateTo;
      $scope.Filter.statusSelection.value.push(3);
    }
    $scope.order();

    // pagination
    $scope.totalBookings = data.length;
    $scope.currentPage = 1;
  }

  /**
   * Data loader
   */
  function dataLoader(stateName: string, stateParams: object) {
    const stateFunctions = [
      {
        states: [
          "domestic",
          "domestic_week",
          "domestic_daterange",
          "domestic_active",
        ],
        loader: () => {
          getDomesticTransports();
        },
      },
      {
        states: ["domestic_active"],
        loader: () => {
          $scope.Filter.statusSelection.value.push(3);
        },
      },
      {
        states: ["domestic_search"],
        loader: () => {
          $scope.query = currentStateParams.query;
          searchTransports(currentStateParams.query);
        },
      },
    ];

    runDataLoader();

    function runDataLoader() {
      stateFunctions
        .filter((item) => {
          if (item && item.states.indexOf(stateName) !== -1) return item;
        })
        .map((item) => item.loader)
        .forEach((fn) => {
          fn();
        });
    }
  }

  function loadDateSettings(): void {
    if (currentStateParams.dateFrom && currentStateParams.dateTo) {
      $scope.dateFrom = currentStateParams.dateFrom;
      $scope.dateTo = currentStateParams.dateTo;
    } else {
      try {
        const listPageSetting = UserService.getLocalWebSettingValue(
          USERWEBSETTINGS.DomesticListPage
        );
        if (!listPageSetting) throw new Error("Failed loading settings");
        const domesticSettings: ListPageDateSettings =
          JSON.parse(listPageSetting);

        if (!domesticSettings.DateFrom || !domesticSettings.DateTo) {
          throw new Error("Failed loading settings");
        }
        $scope.dateFrom = domesticSettings.DateFrom;
        $scope.dateTo = domesticSettings.DateTo;
      } catch (e) {
        $scope.dateFrom = moment().subtract(2, "weeks").format("YYYY-MM-DD");
        $scope.dateTo = moment().format("YYYY-MM-DD");
      }
    }
  }

  function isEmptyArray(array: any[]) {
    return ArrayUtilities.isEmptyArray(array);
  }

  function itemExistInArray(array: any[], item: any): boolean {
    return ArrayUtilities.existInArray(array, item);
  }

  $scope.getShipmentMenuItems = function getShipmentMenuItems(
    shipment: any
  ): any {
    return DomesticDropdownService.getShipmentMenuItems(shipment);
  };

  // Filters Selected
  function initFilter() {
    const Filter: any = {};

    Filter.senderHub = {
      value: undefined,
      name: "Brottfararstaður",
      translate: "FILTER_DEPARTURE",
    };
    Filter.recipientHub = {
      value: undefined,
      name: "Ákvörðunarstaður",
      translate: "LABEL_PLACEOFDELIVERY",
    };
    Filter.recipientName = {
      value: undefined,
      name: "Móttakandi",
      translate: "LABEL_CONSIGNEE",
    };
    Filter.senderName = {
      value: undefined,
      name: "Sendandi",
      translate: "LABEL_SHIPPER",
    };
    Filter.senderOrRecipient = {
      value: "all",
      name: "Bókunaraðili",
      translate: "FILTER_BOOKING_PARTY",
    };
    Filter.statusSelection = {
      value: [],
      name: "Status",
      translate: "LABEL_STATUS",
    };
    Filter.externalSignature = {
      value: "all",
      name: "Móttökukvittun",
      translate: "LABEL_PROOFOFDELIVERY",
    };

    return Filter;
  }
}

domesticController.$inject = ["$scope", "$transition$"];
export default domesticController;
