import * as angular from "angular";
import * as moment from "moment";
import { Transition } from "@uirouter/core";
import { UserService, DomesticService } from "Services";

import DomesticController from "Domestic/DomesticController";
import * as domesticTemplate from "Domestic/domestic.html";
import DomesticWaybillModal from "Domestic/waybillModal/DomesticWaybillModal";
import { USER_ROLES } from "Constants/UserRoles";
import { $uiRouter } from "Bootstrap/angular";
import { WindowUtilities, UtilityFunctions } from "Utilities";
import { Object } from "es6-shim";
import RequestsPage from "../requests";

declare var GlobalConfig: GlobalConfig;

export const MAIN_STATE: string = "domestic";

class Domestic {
  constructor() {
    const $state = $uiRouter.stateService;

    const routes: any[] = [
      {
        name: MAIN_STATE,
        url: `/${MAIN_STATE}`,
        parent: "masterPage",
        template: `${domesticTemplate}`,
        controller: DomesticController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD",
        },
        data: {
          roles: [USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_search`,
        url: `/${MAIN_STATE}/search/:query`,
        parent: "masterPage",
        template: `${domesticTemplate}`,
        controller: DomesticController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_SEARCH",
        },
        data: {
          roles: [USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_week`,
        url: `/${MAIN_STATE}/week`,
        parent: "masterPage",
        template: `${domesticTemplate}`,
        controller: DomesticController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: moment().startOf("isoWeek").format("YYYY-MM-DD"),
          dateTo: moment().endOf("isoWeek").format("YYYY-MM-DD"),
          resultText: "LABEL_PERIOD",
        },
        data: {
          roles: [USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_daterange`,
        url: `/${MAIN_STATE}/range/:dateFrom/:dateTo`,
        parent: "masterPage",
        template: `${domesticTemplate}`,
        controller: DomesticController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD",
        },
        data: {
          roles: [USER_ROLES.DOM],
        },
      },
      {
        name: `${MAIN_STATE}_active`,
        url: `/${MAIN_STATE}/active`,
        parent: "masterPage",
        template: `${domesticTemplate}`,
        controller: DomesticController,
        controllerAs: "$ctrl",
        params: {
          dateFrom: undefined,
          dateTo: undefined,
          resultText: "LABEL_PERIOD",
        },
        data: {
          roles: [USER_ROLES.DOM],
        },
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            // Check if we have dates in the state's params
            // If we don't, we have to fetch the dates from API
            // and redirect the transition to the state again
            // with the dates in params.

            const toStateParams = $transition$.params();
            if (toStateParams.dateFrom && toStateParams.dateTo) return true;

            const user = UserService.getUserProfile();
            const selectedCompany = UserService.getSelectedCompany();
            if (!user) return;

            const start = moment()
              .startOf("isoWeek")
              .subtract(4, "weeks")
              .format("YYYY-MM-DD");
            const end = moment()
              .endOf("isoWeek")
              .add(2, "weeks")
              .format("YYYY-MM-DD");

            return DomesticService.companyTransports(
              start,
              end,
              selectedCompany.CompanyRegNo
            ).then((data: DomesticTransportsTileVM) => {
              const dateFrom = moment(data.TransportsActiveDateFrom).format(
                "YYYY-MM-DD"
              );
              const dateTo = moment(data.TransportsActiveDateTo).format(
                "YYYY-MM-DD"
              );
              const newDate = { dateFrom, dateTo };

              // Don't mutate state transition params object, just create a new one
              // which overrides old one.
              const newStateParams = Object.assign({}, toStateParams, newDate);

              // Return the new target transition
              return $uiRouter.stateService.target(
                $transition$.to(),
                newStateParams
              );
            });
          },
        ],
      },
      {
        name: `${MAIN_STATE}_createbooking`,

        resolve: {
          /**
           * Open the popup for the new domestic booking-creation form
           * and reject the (un)resolved promise. This way, the window is
           * opened and no actual state transition takes place.
           */
          popup: () => {
            const path = `${GlobalConfig.ENV.OldServiceWeb}/ForwardBookings.aspx?Popup=1`;
            const newWindow = false;

            WindowUtilities.openPopup(path, "Skrá bókun");

            return Promise.reject(0);
          },
        },
      },
      {
        name: `${MAIN_STATE}_requests`,
        url: `/${MAIN_STATE}/requests`,
        parent: "masterPage",
        component: RequestsPage,
        data: { roles: [USER_ROLES.SHIP] },
      },
    ];

    routes.forEach((item) => $uiRouter.stateRegistry.register(item));
  }
}

angular.module("serviceWebApp").run(Domestic);
