import { UserService, DomesticService } from "Services";
import { ModalUtilities } from "Utilities";
import GeneralLoader from "Utilities/GeneralLoader";
import "./DomesticWaybillModalComponent.ts";

class DomesticWaybillModal {
  openModal = (bookingNo: string, shipmentID: string) => {
    const resolve = {
      transport: async function transport() {
        const currentUser = UserService.getUserProfile();
        const selectedCompany = UserService.getSelectedCompany();
        if (!currentUser) return;
        GeneralLoader.increase();
        return DomesticService.getTransport(
          selectedCompany.CompanyRegNo,
          shipmentID,
          bookingNo
        ).finally(() => {
          GeneralLoader.decrease();
        });
      },
    };

    ModalUtilities.showExtraLargeModal("domesticWaybillModal", resolve);
  };
}
export default new DomesticWaybillModal();
