import { action } from "mobx";

import { USER_ROLES } from "Constants/UserRoles";
import {
  UserService,
  ShipmentService,
  TranslationService,
  DomesticService,
} from "Services";
import { ModalUtilities, WindowUtilities } from "Utilities";
import GeneralLoader from "Utilities/GeneralLoader";

class DomesticDropdownService {
  getShipmentMenuItems = (shipment: any): any => {
    let docs: any;
    return {
      then: (callback: Function) => {
        return new Promise((resolve, reject) => {
          docs = [];
          if (shipment.HasBarCodes) {
            docs.push({
              label: `{{"LABEL_BARCODE" | translate}}`,
              iconClass: "fa fa-print fa-fw",
              onClick: function onClick(event: Event) {
                event.preventDefault();
                DomesticService.openBarcode(
                  shipment.ShpmtId,
                  shipment.BookingType
                );
              },
            });
          }
          if (shipment.ExternalSignatures) {
            docs.push({
              label: `{{"LABEL_PROOFOFDELIVERY" | translate}}`,
              iconClass: "fa fa-print fa-fw",
              onClick: function onClick(event: Event) {
                event.preventDefault();
                DomesticService.openSignature(shipment.ShpmtId);
              },
            });
          }

          callback(docs);

          resolve();
        });
      },
    };
  };

  private openPopup = (url: string, windowName: string): void => {
    WindowUtilities.openPopup(url, windowName, 1020, 750);
  };
}

export default new DomesticDropdownService();
