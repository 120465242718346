import * as angular from "angular";
import * as moment from "moment";
import * as domesticWaybillTemplate from "Domestic/waybillModal/domesticWaybillModal.html";
import { DomesticService } from "Services";
import { UtilityFunctions } from "Utilities";

const domesticWaybillModalComponent: ng.IComponentOptions = {
  template: `${domesticWaybillTemplate}`,
  bindings: {
    modalInstance: "<",
    close: "&",
    dismiss: "&",
    resolve: "<",
  },
  controller: class DomesticWaybillModalController {
    private modalInstance: ng.ui.bootstrap.IModalInstanceService;
    private resolve: any;
    private transports: Array<DomesticTransportWaybill>;
    private transport: DomesticTransportWaybill;

    $onInit = () => {
      this.transports = this.resolve.transports;
      this.transport = this.resolve.transport;
    };

    selectTransport(bookingnum: number) {
      this.transport =
        this.transports[
          this.transports.findIndex((x) => x.Header.BOOKINGNO == bookingnum)
        ];
    }

    thusandSeperator = (num: number) => {
      return num ? UtilityFunctions.thousandFNumber(num) : null;
    };

    formatDate = (date: Date, dateFormat: string) => {
      // Adding a bit of a hack to deal with the null values form concorde
      const parseDate = moment(date);
      const minimumDate = moment("1900-01-01", "YYYY-MM-DD");
      const formattedDate =
        parseDate.isValid() && parseDate.isSameOrAfter(minimumDate)
          ? parseDate.format(dateFormat)
          : "";
      return formattedDate;
    };
  },
};

angular
  .module("serviceWebApp")
  .component("domesticWaybillModal", domesticWaybillModalComponent);
