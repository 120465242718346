import * as React from "react";
import * as ReactDOM from "react-dom";

import { RequestItem } from "./interfaces.d";

import styles from "./RequestExtraInfoView.less";

import moment from "moment";

import { languages, ShipmentDocumentService } from "Services";

declare var GlobalConfig: GlobalConfig;

export default class RequestExtraInfoView extends React.Component<
  { isEmployee: boolean; request: RequestItem },
  { podLink: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      podLink: ""
    };
  }

  setPODLink = async (requestID: number) => {
    const document = await ShipmentDocumentService.getDocuments(
      requestID.toString(),
      ["TruckingPod"]
    ).then((documents: SDCDocument[]) => documents[0]);
    this.setState({
      podLink: document
        ? `${
            GlobalConfig.ENV.ShippingAPI
          }/proxy/documents/file/${document.ID.toString()}`
        : ""
    });
  };

  async componentDidMount() {
    if (this.props.request.Status.Code === 90) {
      await this.setPODLink(this.props.request.RequestID);
    }
  }

  async componentWillReceiveProps(nextProps: {
    isEmployee: boolean;
    request: RequestItem;
  }) {
    if (
      nextProps.request.RequestID !== this.props.request.RequestID &&
      nextProps.request.Status.Code === 90
    ) {
      await this.setPODLink(nextProps.request.RequestID);
    }
  }

  render() {
    const { isEmployee, request } = this.props;
    return (
      <div className={`js-Tile ${styles.view}`}>
        <div className="column">
          <div>
            <div className="lower-info-title">
              {languages("LABEL_DRIVEN_FROM")}
            </div>
            <div className="lower-info-string">
              {request.From.Address1 ? request.From.Address1 : <br />}
            </div>
            <div className="lower-info-string">{request.From.PostCode}</div>
          </div>
          {request.From.Journey.Start || request.From.Journey.End ? (
            <br />
          ) : null}
          <div>
            {request.From.Journey.Start ? (
              <div className="lower-info-string">
                <span>Start: </span>
                {`${moment(request.From.Journey.Start)
                  .format("HH:mm DD.MM.YYYY")
                  .toString()}`}
              </div>
            ) : null}
            {request.From.Journey.End ? (
              <div className="lower-info-string">
                <span>End: </span>
                {`${moment(request.From.Journey.End)
                  .format("HH:mm DD.MM.YYYY")
                  .toString()}`}
              </div>
            ) : null}
          </div>
        </div>
        <div className="column">
          <div>
            <div className="lower-info-title">
              {languages("LABEL_DRIVEN_TO")}
            </div>
            <div className="lower-info-string">
              {request.To.Address1 ? request.To.Address1 : <br />}
            </div>
            <div className="lower-info-string">{request.To.PostCode}</div>
          </div>
          {request.To.Journey.Start || request.To.Journey.End ? <br /> : null}
          <div>
            {request.To.Journey.Start ? (
              <div className="lower-info-string">
                <span>Start: </span>
                {`${moment(request.To.Journey.Start)
                  .format("HH:mm DD.MM.YYYY")
                  .toString()}`}
              </div>
            ) : null}
            {request.To.Journey.End ? (
              <div className="lower-info-string">
                <span>End: </span>
                {`${moment(request.To.Journey.End)
                  .format("HH:mm DD.MM.YYYY")
                  .toString()}`}
              </div>
            ) : null}
          </div>
        </div>
        {request.Contractor.Name ? (
          <div className="column">
            <div>
              {isEmployee ? (
                <div>
                  <div className="lower-info-title">
                    {languages("LABEL_AGENT")}
                  </div>
                  <div className="lower-info-string">
                    {request.Contractor.Name ? request.Contractor.Name : <br />}
                  </div>
                  <div className="lower-info-string">
                    {request.Contractor.Number ? (
                      request.Contractor.Number
                    ) : (
                      <br />
                    )}
                  </div>
                  <br />
                </div>
              ) : null}
              <div>
                <div className="lower-info-title">
                  {languages("LABEL_VEHICLE")}
                </div>
                <div className="lower-info-string">
                  {request.Vehicle ? request.Vehicle : <br />}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="column">
          {(request.Payer.Id || request.Payer.Name) && isEmployee ? (
            <div>
              <div className="lower-info-title">{languages("LABEL_PAYER")}</div>
              <div className="lower-info-string">
                {request.Payer.Name ? request.Payer.Name : request.Payer.Id}
              </div>
              <br />
            </div>
          ) : null}
          <div>
            <div className="lower-info-title">
              {languages("LABEL_REQUESTOR")}
            </div>
            <div className="lower-info-string">
              {request.Requestor.Name ? request.Requestor.Name : <br />}
            </div>
          </div>
        </div>
        <div className="column">
          {request.ContainerNumber ? (
            <div>
              <div className="lower-info-title">
                {languages("LABEL_CONTAINER")}
              </div>
              <div className="lower-info-string">
                <a
                  href={`/containers/${request.ContainerNumber}`}
                  target="_blank"
                >
                  {request.ContainerNumber}
                </a>
              </div>
              <br />
            </div>
          ) : null}
          {request.JobReference ? (
            <div>
              <div className="lower-info-title">
                {languages("LABEL_SEAFREIGHT_BOOKING")}
              </div>
              <div className="lower-info-string">
                <a href={`/shipments/${request.JobReference}`} target="_blank">
                  {request.JobReference}
                </a>
              </div>
            </div>
          ) : null}
          {this.state.podLink ? (
            <div className="lower-info-string">
              <a href={this.state.podLink} target="_blank">
                {languages("LABEL_PROOFOFDELIVERY")}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
