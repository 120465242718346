import * as React from "react";
import * as ReactDOM from "react-dom";

import { RequestItem } from "./interfaces.d";

import styles from "./RequestView.less";
import { Accordion, Icon } from "semantic-ui-react";

import RequestExtraInfoView from "./RequestExtraInfoView";

import moment from "moment";

import { languages } from "Services";

declare var GlobalConfig: GlobalConfig;

export default class RequestView extends React.Component<
  { isEmployee: boolean; request: RequestItem },
  { link: string; isCollapseOpen: boolean; formattedCreateDate: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      link: this.getLink(),
      isCollapseOpen: false,
      formattedCreateDate: moment(this.props.request.DateCreated)
        .format("HH:mm DD.MM.YYYY")
        .toString()
    };
  }

  getLink = (): string => {
    return this.props.request.Version === "2"
      ? `${GlobalConfig.ENV.OldServiceWeb}/PDFDisplay.aspx?Req=${this.props.request.RequestID}`
      : `${GlobalConfig.ENV.OldServiceWeb}/DisplayBookingInquiry.aspx?ReqNo=${this.props.request.RequestID}`;
  };

  componentDidUpdate(oldProps: { isEmployee: boolean; request: RequestItem }) {
    if (oldProps.request.RequestID !== this.props.request.RequestID) {
      this.setState({ link: this.getLink() });
    }
  }

  toggleCollapse = () => {
    this.setState({
      isCollapseOpen: !this.state.isCollapseOpen
    });
  };

  render() {
    const { isEmployee, request } = this.props;
    const { isCollapseOpen, link, formattedCreateDate } = this.state;
    return (
      <div className={`js-Tile single-request-container ${styles.view}`}>
        <Accordion className="row Tile-upper">
          <div className="col-lg-2 col-sm-6 bookingNumberContainer">
            <span className="Tile-upperTitle">
              {languages("LABEL_REQUEST")}
            </span>
            <div className="Tile-upperContent">
              <a href={link} target="_blank">
                {request.RequestID}
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <span className="Tile-upperTitle">
              {languages("LABEL_CREATED_BY")}
            </span>
            <div className="Tile-upperContent">
              {request.User ? request.User.Name : languages("LABEL_UNKNOWN")}
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <span className="Tile-upperTitle">{languages("LABEL_FROM")}</span>
            <div className="Tile-upperContent">
              {request.From ? request.From.Name : languages("LABEL_UNKNOWN")}
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <span className="Tile-upperTitle">{languages("LABEL_TO")}</span>
            <div className="Tile-upperContent">
              {request.To ? request.To.Name : languages("LABEL_UNKNOWN")}
            </div>
          </div>
          <div className="col-lg-1 col-sm-6">
            <span className="Tile-upperTitle">{languages("LABEL_TYPE")}</span>
            <div className="Tile-upperContent no-wrap">
              {languages(request.RequestType)}
            </div>
          </div>
          <div className="col-lg-2 col-sm-6 med-padding-left">
            <span className="Tile-upperTitle">{languages("LABEL_STATUS")}</span>
            <div className="Tile-upperContent">
              {languages(request.Status.Description)}
            </div>
          </div>
          <Accordion.Title
            className="col-lg-1 col-sm-12 acc-title"
            icon={
              isCollapseOpen ? (
                <Icon size="big" name="chevron up" />
              ) : (
                <Icon size="big" name="chevron down" />
              )
            }
            active={isCollapseOpen}
            onClick={this.toggleCollapse}
          ></Accordion.Title>
          <Accordion.Content
            active={isCollapseOpen}
            content={<RequestExtraInfoView {...this.props} />}
          ></Accordion.Content>
          <span className="upper-right-corner">{formattedCreateDate}</span>
        </Accordion>
      </div>
    );
  }
}
