import React from "react";

import {
  Sidebar,
  Button,
  Form,
  Radio,
  Checkbox,
  DropdownItemProps
} from "semantic-ui-react";

import { Collapse, Input, Dropdown } from "../../components";

import DatePicker from "react-datepicker";

import { RequestFilters } from "./interfaces.d";

import styles from "./Sidebar.less";

import { ArrayUtilities } from "Utilities";

import { languages, RequestService } from "Services";

export default class RequestSidebar extends React.Component<
  {
    visible: boolean;
    onClose: Function;
    filters: RequestFilters;
    onFilter: Function;
    disabled: boolean;
    selectedCompany: Company;
    isEmployee: boolean;
    onSearch: Function;
    departments: DropdownItemProps[];
    requestTypes: DropdownItemProps[];
    clearFilters: Function;
  },
  {
    requestStatuses: { Code: number; Description: string }[];
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      requestStatuses: []
    };
  }

  async componentDidMount() {
    const requestStatuses = await RequestService.getRequestStatuses();

    this.setState({
      requestStatuses
    });
  }
  render() {
    const {
      onClose,
      onFilter,
      filters,
      disabled,
      selectedCompany,
      onSearch,
      clearFilters
    } = this.props;
    return (
      <Sidebar
        className={`js-Sidebar ${styles.filter}`}
        animation="overlay"
        icon="labeled"
        direction="right"
        visible={this.props.visible}
      >
        <Button onClick={() => onClose()} icon="chevron right" />
        <Button onClick={() => clearFilters()} disabled={disabled}>
          {languages("LABEL_CLEAR")}
        </Button>
        <Button
          onClick={() => onSearch()}
          icon="search"
          floated="right"
          disabled={disabled}
          loading={disabled}
        />
        <Collapse title={languages("FILTER_REQUEST")} open={true}>
          <Input
            disabled={disabled}
            type="number"
            value={filters.RequestNumber}
            onChange={(n: string) =>
              onFilter(
                "RequestNumber",
                "FILTER_REQUEST",
                n,
                false
              )
            }
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                onFilter(
                  "RequestNumber",
                  "FILTER_REQUEST",
                  filters.RequestNumber,
                  true
                );
              }
            }}
            onBlur={() => {
              onFilter(
                "RequestNumber",
                "FILTER_REQUEST",
                filters.RequestNumber,
                false
              );
            }}
          ></Input>
        </Collapse>
        <Collapse title={languages("FILTER_CONTAINER")} open={true}>
          <Input
            disabled={disabled}
            type="text"
            value={filters.ContainerNumber}
            onChange={(n: string) =>
              onFilter(
                "ContainerNumber",
                "FILTER_CONTAINER",
                n,
                false
              )
            }
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                onFilter(
                  "ContainerNumber",
                  "FILTER_CONTAINER",
                  filters.ContainerNumber,
                  true
                );
              }
            }}
            onBlur={() => {
              onFilter(
                "ContainerNumber",
                "FILTER_CONTAINER",
                filters.ContainerNumber,
                false
              );
            }}
          ></Input>
        </Collapse>
        <Collapse title={languages("FILTER_DATERANGE")} open={true}>
          <span className="fl-l">
            <DatePicker
              className="ui input"
              dateFormat="dd/MM/yyyy"
              peekNextMonth
              showWeekNumbers
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
              disabled={disabled}
              selected={filters.DateFrom}
              customInput={<Input />}
              onChange={(date: Date) => {
                if (date.getTime() > filters.DateTo.getTime()) {
                  onFilter(
                    "DateTo",
                    "",
                    date,
                    false
                  );
                }
                onFilter(
                  "DateFrom",
                  "",
                  date,
                  false
                );
              }}
              popperModifiers={{
                preventOverflow: {
                  enabled: true
                }
              }}
              selectsStart
            />
            <DatePicker
              className="ui input"
              peekNextMonth
              showWeekNumbers
              formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
              dateFormat="dd/MM/yyyy"
              disabled={disabled}
              selected={filters.DateTo}
              customInput={<Input />}
              minDate={filters.DateFrom}
              onChange={date =>
                onFilter(
                  "DateTo",
                  "",
                  date,
                  false
                )
              }
              popperModifiers={{
                preventOverflow: {
                  enabled: true
                }
              }}
              selectsEnd
            />
          </span>
        </Collapse>
        {this.props.isEmployee && (
          <Collapse
            className="margin-top-55"
            title={languages("FILTER_RESTRICT")}
            open={true}
          >
            <Form.Group>
              <Form.Field
                disabled={disabled}
                name="partner"
                control={Radio}
                label={languages("LABEL_CHOSENCUSTOMER")}
                value={selectedCompany.PartnerCode}
                checked={filters.Partner === selectedCompany.PartnerCode}
                onChange={(e: any, data: any) =>
                  onFilter(
                    "Partner",
                    "LABEL_CUSTOMERGROUP",
                    data.value,
                    false
                  )
                }
              ></Form.Field>
              <Form.Field
                disabled={disabled}
                name="partner"
                control={Radio}
                label={languages("LABEL_ALLCUSTOMERS")}
                value={""}
                checked={filters.Partner === ""}
                onChange={(e: any, data: any) =>
                  onFilter(
                    "Partner",
                    "LABEL_CUSTOMERGROUP",
                    data.value,
                    false
                  )
                }
              ></Form.Field>
            </Form.Group>
          </Collapse>
        )}
        <Collapse title={languages("FILTER_REQUEST_TYPE")} open={true}>
          <Form.Group className="margin-bottom-10">
            <Form.Field
              disabled={disabled}
              name="type"
              control={Radio}
              label={languages("LABEL_ALLREQUESTS")}
              value={""}
              checked={filters.RequestVersion === ""}
              onChange={(e: any, data: any) =>
                onFilter(
                  "RequestVersion",
                  "FILTER_REQUEST_TYPE",
                  data.value,
                  false
                )
              }
            ></Form.Field>
            <Form.Field
              disabled={disabled}
              name="type"
              control={Radio}
              label={languages("LABEL_LINKED_BOOKING")}
              value={"1"}
              checked={filters.RequestVersion === "1"}
              onChange={(e: any, data: any) =>
                onFilter(
                  "RequestVersion",
                  "FILTER_REQUEST_TYPE",
                  data.value,
                  false
                )
              }
            ></Form.Field>
            <Form.Field
              disabled={disabled}
              name="type"
              control={Radio}
              label={languages("LABEL_SINGLE_REQUESTS")}
              value={"2"}
              checked={filters.RequestVersion === "2"}
              onChange={(e: any, data: any) =>
                onFilter(
                  "RequestVersion",
                  "FILTER_REQUEST_TYPE",
                  data.value,
                  false
                )
              }
            ></Form.Field>
          </Form.Group>
          <Dropdown
            value={filters.RequestType}
            disabled={disabled}
            options={this.props.requestTypes}
            onChange={(value: any) =>
              onFilter(
                "RequestType",
                "LABEL_TYPE",
                value,
                false
              )
            }
            placeholder={languages("REQUEST_TYPE_FILTER_PLACEHOLDER")}
            clearable
          ></Dropdown>
        </Collapse>
        <Collapse title={languages("FILTER_STATUS")} open={true}>
          <Form.Group>
            {this.state.requestStatuses.map(data => {
              return (
                <Form.Field
                  key={data.Code}
                  disabled={disabled}
                  name="status"
                  control={Checkbox}
                  checked={ArrayUtilities.includes(
                    filters.RequestStatuses,
                    data.Code
                  )}
                  value={data.Code}
                  label={languages(data.Description)}
                  onChange={(e: any, d: any) =>
                    onFilter(
                      "RequestStatuses",
                      "FILTER_STATUS",
                      ArrayUtilities.toggleValueInArray(
                        filters.RequestStatuses,
                        d.value
                      ),
                      false
                    )
                  }
                ></Form.Field>
              );
            })}
          </Form.Group>
        </Collapse>
        <Collapse title={languages("LABEL_DEPARTMENT")} open={true}>
          <Dropdown
            value={filters.Department}
            disabled={disabled}
            options={this.props.departments}
            onChange={(value: any) =>
              onFilter(
                "Department",
                "LABEL_DEPARTMENT",
                value,
                false
              )
            }
            placeholder={languages("LABEL_CHOOSE_DEPARTMENT")}
            clearable
          ></Dropdown>
        </Collapse>
      </Sidebar>
    );
  }
}
